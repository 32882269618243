import { Link, navigate } from "gatsby"
import React, { useState, useEffect } from "react"

// Styles
import styles from "./header.module.sass"

// Icons
import MadfishLogo from "../../../icons/madfish.svg"
import MenuOpenIcon from "../../../icons/menu-open.svg"
import MenuCloseIcon from "../../../icons/menu-close.svg"
import PhoneIcon from "../../../icons/menu-phone.svg"
import FacebookIcon from "../../../icons/socials/facebook.svg"
import TwitterIcon from "../../../icons/socials/twitter.svg"
import LinkedInIcon from "../../../icons/socials/linkedin.svg"
import GithubIcon from "../../../icons/socials/github.svg"
import RedditIcon from "../../../icons/socials/reddit.svg"
import ClutchIcon from "../../../icons/socials/clutch.svg"
import GoodFirmsIcon from "../../../icons/socials/good-firms.svg"
import ArrowRight from "../../../icons/arrow-right.svg"
import { disableScroll, enableScroll } from "./toggleScroll"

const Header = () => {
  const [headerClass, setHeaderClass] = useState(``)

  useEffect(() => {
    window.addEventListener("scroll", resizeHeaderOnScroll)
  }, [])

  const resizeHeaderOnScroll = () => {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 20
    if (distanceY > shrinkOn) {
      setHeaderClass(styles.smaller)
    } else {
      // headerEl.classList.add("smaller");
      setHeaderClass(``)
      // headerEl.classList.remove("smaller");
    }
  }

  const [showMenuState, setShowMenuState] = useState(false)

  const showMenu = () => {
    setShowMenuState(true)
    disableScroll()
  }
  const hideMenu = () => {
    setShowMenuState(false)
    enableScroll()
  }

  const getCoords = elem => {
    const box = elem.getBoundingClientRect()

    return box.top + window.pageYOffset
  }
  const handleScroll = async elem => {
    if (document.querySelector(elem)) {
      window.scrollTo({
        top:
          getCoords(document.querySelector(elem)) +
          parseFloat(
            window
              .getComputedStyle(document.querySelector(elem), null)
              .getPropertyValue("padding-top")
          ) -
          document.querySelector(`.${styles.header}`).offsetHeight,
        behavior: "smooth",
      })
    } else {
      navigate(`/${elem}`)
    }
  }

  const handleMobileButtonClick = (e, path) => {
    e.preventDefault()
    enableScroll()
    navigate(path)
  }

  return (
    <>
      <header className={styles.header + ` ` + headerClass + " header"}>
        <div className="container">
          <div className="row v-center space-between">
            <div className={styles.logo}>
              <Link to="/">
                <MadfishLogo />
                <span>madfish.</span>
              </Link>
            </div>
            <nav className={styles.nav}>
              <ul className={styles.navLinks}>
                <li>
                  <Link to="/">Main</Link>
                </li>
                <li>
                  <Link to="/services">Services</Link>
                </li>
                {/*<li>*/}
                {/*  <Link to="/">Cases</Link>*/}
                {/*</li>*/}
                <li>
                  <Link to="/about-us">About us</Link>
                </li>
                <li>
                  <Link to="/how-we-work">How we work</Link>
                </li>
                <li>
                  <a
                    href="https://artkhalin.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Careers
                  </a>
                </li>
                {/* <li>
                  <Link to="/blog">Blog</Link>
                </li> */}
              </ul>
              <a
                href="https://artkhalin.com"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.button + " button button-primary small"}
              >
              <span className="content">Book a call</span>
              </a>
            </nav>
            <div className={styles.mobileButtons}>
              <a
                href="https://artkhalin.com"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.button + " button button-primary small"}
              >
                <span className="content">
                  <span>Book a call</span>
                  <PhoneIcon />
                </span>
              </a>
              <button className={styles.openMenu} onClick={() => showMenu()}>
                <MenuOpenIcon />
              </button>
            </div>
          </div>
        </div>
      </header>
      <div
        className={
          styles.mobileOverlay + " " + (showMenuState && styles.active)
        }
        onClick={() => hideMenu()}
      />
      <nav
        className={
          styles.nav +
          " " +
          styles.navMobile +
          " " +
          (showMenuState && styles.active)
        }
      >
        <ul className={styles.navLinks}>
          <li>
            <Link to="/" onClick={e => handleMobileButtonClick(e, "/")}>
              Main
            </Link>
          </li>
          <li>
            <Link
              to="/services"
              onClick={e => handleMobileButtonClick(e, "/services")}
            >
              Services
            </Link>
          </li>
          {/*<li>*/}
          {/*  <Link to="/">Cases</Link>*/}
          {/*</li>*/}
          <li>
            <Link
              to="/about-us"
              onClick={e => handleMobileButtonClick(e, "/about-us")}
            >
              About us
            </Link>
          </li>
          <li>
            <Link
              to="/how-we-work"
              onClick={e => handleMobileButtonClick(e, "/how-we-work")}
            >
              How we work
            </Link>
          </li>
          <li>
            <a
              href="https://artkhalin.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Careers
            </a>
          </li>
          {/* <li>
            <a
              href="https://medium.com/madfish-solutions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Blog
            </a>
          </li> */}
          <li>
            <button
              className={styles.mobileContact}
              onClick={() => {
                hideMenu()
                handleScroll(`#contactsScreen`)
              }}
            >
              Contact Us
              <ArrowRight />
            </button>
          </li>
        </ul>
        <div className={styles.socials}>
          <div className={styles.socialTop}>
            <a
              href="https://www.facebook.com/madfishofficial/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon />
            </a>
            <a
              href="https://twitter.com/madfishofficial"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon />
            </a>
            <a
              href="https://www.linkedin.com/company/mad-fish-solutions"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon />
            </a>
            <a
              href="https://github.com/madfish-solutions"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GithubIcon />
            </a>
            <a
              href="https://www.reddit.com/user/MadFishSolutions/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <RedditIcon />
            </a>
          </div>
          <div className={styles.socialBottom}>
            <a
              href="https://clutch.co/profile/madfishsolutions"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ClutchIcon />
            </a>
            <a
              href="https://www.goodfirms.co/company/madfish-solutions"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GoodFirmsIcon />
            </a>
          </div>
        </div>
        <button className={styles.closeMenu} onClick={() => hideMenu()}>
          <MenuCloseIcon />
        </button>
        {/*<a*/}
        {/*  href="https://calendly.com/madfish-solutions/15min"*/}
        {/*  target="_blank"*/}
        {/*  rel="noopener noreferrer"*/}
        {/*  className={styles.button + " button button-primary small"}*/}
        {/*>*/}
        {/*  <span className="content">Book a call</span>*/}
        {/*</a>*/}
      </nav>
    </>
  )
}

export default Header
