import React from "react"

import styles from "./tweet.module.sass"

import TwitterIcon from "../../../../icons/socials/twitter.svg"

const Tweet = ({ tweet }) => (
  <div className={styles.tweet}>
    <a
      href={tweet.link}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.link}
    />
    <div className={styles.inner}>
      <div className={styles.top}>
        <div className={styles.image}>
          <img
            src="https://pbs.twimg.com/profile_images/1513891989443563526/a7BWig9S_normal.png"
            alt={tweet.content}
          />
        </div>
        <div className={styles.info}>
          <div className={styles.name}>
          madfishofficial <TwitterIcon />
          </div>
          <div>@madfishofficial</div>
        </div>
      </div>
      <div className={styles.content}>{tweet.content}</div>
    </div>
  </div>
)

export default Tweet
