import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import styles from "./footer.module.sass"

import LocationIcon from "../../../icons/location.svg"

import FacebookIcon from "../../../icons/socials/facebook.svg"
import TwitterIcon from "../../../icons/socials/twitter.svg"
import LinkedInIcon from "../../../icons/socials/linkedin.svg"
import GithubIcon from "../../../icons/socials/github.svg"
import RedditIcon from "../../../icons/socials/reddit.svg"
import ClutchIcon from "../../../icons/socials/clutch.svg"
import GoodFirmsIcon from "../../../icons/socials/good-firms.svg"
import Tweet from "./Tweet/tweet"
import MadfishLogo from "../../../icons/madfish-light.svg"

const tweets = [
  {
    id: 0,
    tweet: {
      link: "https://twitter.com/madfishofficial/status/1598708795416674304",
      content: "How to earn with the Yupana lending protocol? https://t.co/vh4RJYLvfG",
    },
  },
  {
    id: 1,
    tweet: {
      link: "https://twitter.com/madfishofficial/status/1606357801311145984",
      content: "The results of our #NFT raffle in collaboration with @quasimondo are ready! Check all info here:  https://t.co/wKxZvustS7 All people who were brave enough to participate in this event and move funds from BNB/Polygon chains to #Tezos were rewarded🥳 NFT distribution will be soon😃 https://t.co/epknA8UMoL",
    },
  },
  {
    id: 2,
    tweet: {
      link: "https://twitter.com/madfishofficial/status/1609142494469447681",
      content: "Dear Madfish friends. Happy New year and Merry Christmas! Thank you for going through the 2022 year with us and for your participation, advice, and activity. We are proud to have the best community in the whole crypto world ❤️ Happy holidays to you, friends! https://t.co/3gBB8AKPv8",
    },
  },
]

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className="row flex-wrap">
          <div className={styles.top}>
            <div className={styles.row}>
              <h4>Contact</h4>
              <div className={styles.location}>
                <div className={styles.locationIcon}>
                  <LocationIcon />
                </div>
                <div className={styles.locationContent}>
                  <a
                    href="https://www.google.com.ua/maps/place/%D1%83%D0%BB.+%D0%9F%D0%BE%D0%BB%D0%B5%D0%B2%D0%B0%D1%8F,+21,+%D0%9A%D0%B8%D0%B5%D0%B2,+02000/@50.4448114,30.4504627,17z/data=!3m1!4b1!4m5!3m4!1s0x40d4cc20a8eb5247:0x9d99b60a49ca1780!8m2!3d50.4448114!4d30.4526514?hl=ru"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Kyiv, Polova 21 Str.
                  </a>
                  <a href="tel:+380676112215">+38-067-611-22-15</a>
                </div>
              </div>
              <div className={styles.location}>
                <div className={styles.locationIcon}>
                  <LocationIcon />
                </div>
                <div className={styles.locationContent}>
                  <a
                    href="https://www.google.com.ua/maps/place/%C3%96piku+maja,+Valukoja+8,+11415+Tallinn,+%D0%AD%D1%81%D1%82%D0%BE%D0%BD%D0%B8%D1%8F/@59.4200831,24.8025974,17z/data=!3m1!4b1!4m5!3m4!1s0x4692eb570378622f:0x80dec7c12d2c0539!8m2!3d59.4200831!4d24.8047861?hl=ru"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Tallinn, Valukoja tn 8/1
                  </a>
                  <a href="tel:11415">11415</a>
                </div>
              </div>
              <div className={styles.social}>
                <div className={styles.socialTop}>
                  <a
                    href="https://www.facebook.com/madfishofficial/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookIcon />
                  </a>
                  <a
                    href="https://twitter.com/madfishofficial"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TwitterIcon />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/mad-fish-solutions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedInIcon />
                  </a>
                  <a
                    href="https://github.com/madfish-solutions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <GithubIcon />
                  </a>
                  <a href="https://www.reddit.com/user/MadFishSolutions/" target="_blank" rel="noopener noreferrer">
                    <RedditIcon />
                  </a>
                </div>
                <div className={styles.socialBottom}>
                  <a
                    href="https://clutch.co/profile/madfishsolutions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ClutchIcon />
                  </a>
                  <a
                    href="https://www.goodfirms.co/company/madfish-solutions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <GoodFirmsIcon />
                  </a>
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <h4>Industries</h4>
              <ul>
                <li>
                  <span>Smart Contracts</span>
                </li>
                <li>
                  <span>DApp/DeFi Solutions</span>
                </li>
                <li>
                  <span>FinTech</span>
                </li>
                <li>
                  <span>Blockchain Solutions</span>
                </li>
                <li>
                  <span>Custom CRM/ERP</span>
                </li>
                <li>
                  <span>
                    Cryptocurrency/Blockchain Infrastructure Solutions
                  </span>
                </li>
                <li>
                  <span>Consultancy</span>
                </li>
                <li>
                  <span>Cryptocurrencies Payment Systems</span>
                </li>
                <li>
                  <span>Game theory/Economic analysis</span>
                </li>
              </ul>
            </div>
            <div className={styles.row}>
              <h4>Services</h4>
              <ul>
                <li>
                  <span>Discovery</span>
                </li>
                <li>
                  <span>R&D and technology innovation</span>
                </li>
                <li>
                  <span>Blockchain solutions</span>
                </li>
                <li>
                  <span>Feedbacks</span>
                </li>
                <li>
                  <span>Hardware Engineering</span>
                </li>
                <li>
                  <span>Technology consulting</span>
                </li>
                <li>
                  <span>Developing</span>
                </li>
                <li>
                  <span>Design</span>
                </li>
              </ul>
            </div>
            <div className={styles.row + " " + styles.twitter}>
              <h4>Last tweets</h4>
              {tweets.map(
                ({id, tweet}) => (
                  <Tweet key={id} tweet={tweet} />
                )
              )}
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.logo}>
              <Link to="/">
                <MadfishLogo />
                <span>madfish.</span>
              </Link>
            </div>
            <nav className={styles.nav}>
              <ul className={styles.navLinks}>
                <li>
                  <Link to="/terms-of-use">Terms of Use</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                {/*<li>*/}
                {/*  <Link to="/">Cookies</Link>*/}
                {/*</li>*/}
              </ul>
            </nav>
            <div className={styles.copy}>
              © {new Date().getFullYear()} MadFish All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
