/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Global/Header/header"
import "./layout.css"
import CookieConsent from "react-cookie-consent"

import "../styles/styles.sass"
import Footer from "./Global/Footer/footer"

function debounce(fn, ms) {
  let timer
  return () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    document.addEventListener("copy", event => {
      const pagelink = `\n\nRead more at: ${document.location.href}\n💚️Madfish.Solutions`
      event.clipboardData.setData("text", document.getSelection() + pagelink)
      event.preventDefault()
    })
  }, [])

  const [dimensions, setDimensions] = useState({
    height: 1,
    width: 1,
  })

  useEffect(() => {
    let vh = dimensions.height * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)

    // const HandleResize = () => {
    //   setDimensions({
    //     height: window.innerHeight,
    //     width: window.innerWidth
    //   });
    // };

    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }, 1000)

    debouncedHandleResize()

    window.addEventListener("resize", debouncedHandleResize)

    return () => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  })

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>
        <div className="container">{children}</div>
      </main>
      <Footer />
      <div className="cookieWrapper">
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics"
          containerClasses="cookie"
          disableStyles={true}
          disableButtonStyles={true}
          buttonClasses='buttonCookie'
          // enableDeclineButton
          // declineButtonClasses='buttonCookieDecline'
        >
          <p>
            This website stores cookies on your computer. These cookies are used
            to collect information about how you interact with this website and
            allow us to remember you.
          </p>
          <p>
            We use this information in order to improve and customize your
            browsing experience and for analytics and metrics about our visitors
            on this website.
          </p>
          {/*<p>*/}
          {/*  If you decline, your information won’t be tracked when you visit*/}
          {/*  this website. A single cookie will be used in your browser to*/}
          {/*  remember your preference not to be tracked.*/}
          {/*</p>*/}
        </CookieConsent>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
